<template>
  <!-- 客户中心 - 企业招工 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业招聘</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">企业招工</a>
          <i>></i>
          <a href="javascript:;">查看联系人</a>

        </span>
      </div>
      <div class="framePage-body">
        <div><h4 class="topInfo" style="margin-bottom: 20px;">招聘岗位：{{ row.recruitmentPost }}  &nbsp; 企业名称：{{ row.compName }}  &nbsp;
          工作地点：{{ row.areaNamePath }}</h4></div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
<!--              <div title="招工状态" class="searchboxItem ci-full">-->
<!--                <span class="itemLabel">招工状态:</span>-->
<!--                <el-select-->
<!--                    v-model="status"-->
<!--                    placeholder="请选择"-->
<!--                    size="small"-->
<!--                    clearable-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in statusList"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value"-->
<!--                  ></el-option>-->
<!--                </el-select>-->
              <el-button
                  style="margin-left: 20px"
                  class="bgc-bv"
                  round
                  @click="exportList"
              >导出</el-button
              >
<!--            </div>-->
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />
              <el-table-column
                  label="姓名"
                  align="left"
                  prop="userName"
                  show-overflow-tooltip
                  min-width="120"
              />
              <el-table-column
                  label="手机号"
                  align="left"
                  prop="mobile"
                  show-overflow-tooltip
                  min-width="150"
              />
              <el-table-column
                  label="性别"
                  align="left"
                  prop="sex"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("SEX_REQUIREMENT", scope.row.sex) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="所在城市"
                  align="left"
                  prop="areaNamePath"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="出生日期"
                  align="left"
                  prop="birthday"
                  show-overflow-tooltip
                  min-width="100"
              />
<!--              <el-table-column-->
<!--                  label="薪酬范围"-->
<!--                  align="left"-->
<!--                  prop="salaryRange"-->
<!--                  show-overflow-tooltip-->
<!--                  min-width="100"-->
<!--              >-->
<!--                <template slot-scope="scope">-->
<!--                  {{ $setDictionary("SALARY_RANGE", scope.row.salaryRange) }}-->
<!--                </template>-->
<!--              </el-table-column>-->

              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import {resetKeepAlive} from "@/utils/common";
export default {
  name: "accountManagement_viewContact",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      row: {},
      status: '',
      statusList: []
      // 顶部广告
      // topAdvertisement: false,
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.row = this.$route.query.row
    console.log(this.$route.query.row)
  },
  methods: {
    // 导出
    exportList() {
      this.$post("/biz/recruitment/applicantExport",{pageNum:1,pageSize: 10,recruitmentId: this.$route.query.row.recruitmentId})
          .then((res) => {
            const arr=[...res.data];
            for (let item of arr) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          })
          .catch(() => {
            return;
          });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        recruitmentId: this.$route.query.row.recruitmentId
      };
      this.doFetch({
        url: "/biz/recruitment/getApplicant",
        params,
        pageNum,
      });
    },
  },

  // beforeRouteLeave: resetKeepAlive,
  // watch: {
  //   $route: {
  //     handler: function (val, oldVal) {
  //       if (val.query.refresh == false) {
  //         this.getData(-1);
  //       }
  //     },
  //     // 深度观察监听
  //     deep: true,
  //   },
  // },
};
</script>
<style lang="less" scoped>
.framePage .framePage-body{
  display: block;
  flex: initial;
}
.operationControl > div > div > span, .operationControl > div span > span {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.topInfo{
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}
</style>
